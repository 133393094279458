.appointment-details-grid-container {
  display: grid;
  grid-template-areas:
    'header header header header '
    'leadtitle leadtitle leadtitle leadtitle  '
    'leadname leadname leadname leadname '
    'org org org org '
    'adr adr adr adr  '
    'contact contact contact contact '
    'phone phone phone phone  '
    'role role role role  '
    'email email email email  '
    'detailstitle detailstitle detailstitle detailstitle  '
    'date date time time '
    'digital digital digital digital'
    'note note note note'
    'q q q q  '
    'q q q q  '
    'q q q q  '
    'q q q q  '
    'q q q q  '
    'feedback feedback feedback feedback  '
    'feedback feedback feedback feedback  '
    'feedback feedback feedback feedback  '
    '. . . feedbackbutton  '
    '.  . cancel .  ';
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  // grid-template-rows: 80px 80px 140px;
  grid-column-gap: 8px;
  grid-row-gap: 16px;

  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-areas:
      'header header header header header header header header'
      'leadtitle leadtitle . . . q q q '
      'leadname leadname org org . q q q '
      'adr adr adr adr . q q q '
      'contact contact phone phone . q q q '
      'role role email email . q q q '
      'detailstitle detailstitle detailstitle detailstitle . feedback feedback feedback '
      'date date time time . feedback feedback feedback'
      'digital digital note note . feedback feedback feedback '
      '. . . . . . . feedbackbutton  '
      '. . .  . cancel . . .   ';
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    // grid-template-rows: 80px 80px 140px;
    grid-column-gap: 8px;
    grid-row-gap: 16px;
  }

  h2 {
    font-size: 36px;
    color: rgb(53, 58, 69) !important;
  }
  p {
    font-size: 16px;
    color: rgb(53, 58, 69) !important;
    margin-bottom: 4px;
    margin-left: 8px;
  }
  .appointment-details-header {
    background-color: rgb(180, 247, 200);
    grid-area: header;
    min-height: 100px;
    min-width: 100%;
    border-radius: 20px 20px 0 0;
    // display: flex;
    // align-items: center;
    padding: 2rem 0;

    h2 {
      font-size: 42px;
      margin: 0;
      margin-left: 8px;
      @media only screen and (min-width: 1024px) {
        margin-left: 2rem;
      }
      width: 100%;
    }

    p {
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }

  .appointments-details-lead-header {
    grid-area: leadtitle;
    h2 {
      margin-left: 8px;
      @media only screen and (min-width: 1024px) {
        margin-left: 2rem;
      }
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }

  .appointments-details-question-container {
    margin-left: 8px;
    @media only screen and (min-width: 1024px) {
      margin-right: 2rem;
    }
    grid-area: q;
    h2 {
      margin-top: 1rem;
    }
  }

  .appointments-details-leadname {
    grid-area: leadname;
    @media only screen and (min-width: 1024px) {
      margin-left: 2rem;
    }
  }

  .appointments-details-org {
    grid-area: org;
  }

  .appointments-details-adr {
    grid-area: adr;
    @media only screen and (min-width: 1024px) {
      margin-left: 2rem;
    }
  }

  .appointments-details-contact {
    grid-area: contact;
    @media only screen and (min-width: 1024px) {
      margin-left: 2rem;
    }
  }

  .appointments-details-phone {
    grid-area: phone;
    @media only screen and (min-width: 1024px) {
      margin-left: 2rem;
    }
  }

  .appointments-details-role {
    grid-area: role;
    @media only screen and (min-width: 1024px) {
      margin-left: 2rem;
    }
  }

  .appointments-details-email {
    grid-area: email;
    @media only screen and (min-width: 1024px) {
      margin-left: 2rem;
    }
  }

  .appointments-details-detailstitle {
    grid-area: detailstitle;
    margin-left: 8px;
    h2 {
      @media only screen and (min-width: 1024px) {
        margin-left: 2rem;
      }
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }

  .appointments-details-feedback-container {
    grid-area: feedback;
    margin-left: 8px;
    @media only screen and (min-width: 1024px) {
      margin-right: 2rem;
    }

    h2 {
      margin-top: 1rem;
    }
  }

  .appointments-details-date {
    grid-area: date;
    @media only screen and (min-width: 1024px) {
      margin-left: 2rem;
    }
  }

  .appointments-details-time {
    grid-area: time;
    @media only screen and (min-width: 1024px) {
      margin-left: 2rem;
    }
  }

  .appointments-details-digital {
    grid-area: digital;
    @media only screen and (min-width: 1024px) {
      margin-left: 2rem;
    }
  }

  .appointments-details-note {
    grid-area: note;
    @media only screen and (min-width: 1024px) {
      margin-left: 2rem;
    }
  }
  .appointments-details-feedbackbutton {
    grid-area: feedbackbutton;
    @media only screen and (min-width: 1024px) {
      margin-right: 2rem;
    }
  }
}
