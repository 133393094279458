.create-appointment-container {
    display: grid;
    grid-template-areas:
      'header header header header header'
      'title title title title . '
      'customer customer customer customer . '
      'lead lead lead lead addleadbtn '
      'date date meetinglength meetinglength .'
      'digital digital digital digital .'
      'questions questions questions questions .'
      'note note note note .'
      '. . . canceladd canceladd';
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    // grid-template-rows: 80px 80px 140px;
    grid-column-gap: 8px;
    grid-row-gap: 16px;
    // justify-items: start;
  
    .ant-form-item-label {
      padding-bottom: 0 !important;
    }
  
    h2 {
      font-size: 36px;
      color: rgb(53, 58, 69) !important;
    }
    p {
      font-size: 16px;
      color: rgb(53, 58, 69) !important;
      margin-bottom: 4px;
      margin-left: 8px;
    }
  
    .ant-form-item {
      margin-bottom: 0;
    }
  
    .create-appointment-header {
      background-color: rgb(180, 247, 200);
      grid-area: header;
      min-height: 100px;
      min-width: 100%;
      border-radius: 20px 20px 0 0;
      // display: flex;
      // align-items: center;
      padding: 2rem 0;
  
      h2 {
        font-family: FuturaMedium;
        font-size: 42px;
        margin: 0;
        margin-left: 2rem;
        width: 100%;
      }
  
      p {
        font-family: FuturaMedium;
        margin-left: 2rem;
        margin-bottom: 0;
      }
    }
  
    .create-appointment-title {
      grid-area: title;
      h2 {
        font-family: FuturaMedium;
        font-size: 24px;
        margin-left: 2rem;
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }
  
    .create-appointment-customer {
      grid-area: customer;
      margin-left: 2rem;
    }
  
    .create-appointment-lead {
      grid-area: lead;
      margin-left: 2rem;
    }
  
    .create-appointment-addleadbtn {
      grid-area: addleadbtn;
      display: flex;
      .addleadbtn {
        margin-bottom: 2px;
        align-self: flex-end;
        background-color: rgb(180, 247, 200);
        color: rgb(53, 58, 69);
        border: none;
      }
    }
  
    .create-appointment-date {
      grid-area: date;
      margin-left: 2rem;
      .ant-picker {
        border-radius: 20px;
        border-color: rgb(53, 58, 69);
        .ant-picker-input > input::placeholder {
          color: rgb(53, 58, 69);
        }
      }
    }
    .create-appointment-meetinglength {
      grid-area: meetinglength;
    }
    .create-appointment-digital {
      grid-area: digital;
      margin-left: 2rem;
  
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: white;
        border-color: rgb(53, 58, 69);
      }
      .ant-checkbox-checked .ant-checkbox-inner:after {
        border-color: rgb(53, 58, 69);
        color: rgb(53, 58, 69);
        transform: rotate(45deg) scale(1.6) translate(-10%, -40%);
      }
      .ant-checkbox {
        background-color: white !important;
        color: rgb(53, 58, 69) !important;
        // border: 1px solid rgb(53, 58, 69);
      }
      .ant-checkbox-inner {
        background-color: white;
        color: rgb(53, 58, 69) !important;
        border: 1px solid rgb(53, 58, 69);
        border-radius: 5px;
        width: 25px;
        height: 25px;
      }
      .ant-checkbox-checked {
        background-color: white;
        color: rgb(53, 58, 69);
      }
    }
    .create-appointment-questions {
      grid-area: questions;
      margin-left: 2rem;
  
      h2 {
        font-family: FuturaMedium;
        font-size: 24px;
        margin-bottom: 0;
      }
    }
    .create-appointment-note {
      grid-area: note;
      margin-left: 2rem;
    }
  }
  