// @import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: 'MontHeavy';
  font-weight: normal;
  src: local('MontHeavy'), url(./fonts/Mont-Heavy.otf) format('opentype');
}
@font-face {
  font-family: 'FuturaBold';
  font-weight: normal;
  src: local('Futura'), url(./fonts/FuturaBold.otf) format('opentype');
}
@font-face {
  font-family: 'FuturaLight';
  font-weight: normal;
  src: local('Futura'), url(./fonts/futura/Futura-CondensedLight.otf) format('opentype');
}
@font-face {
  font-family: 'Futura';
  font-weight: normal;
  src: local('Futura'), url(./fonts/futura/Futura_Book_font.ttf) format('opentype');
}
@font-face {
  font-family: 'FuturaMedium';
  font-weight: 100;
  src: local('Futura'), url(./fonts/futura/FuturaHeavyfont.ttf) format('opentype');
}

p {
  font-family: 'Futura';
  color: rgb(180, 247, 200);
}

h1,
h2 {
  font-family: 'FuturaBold';
  color: rgb(180, 247, 200) !important;
}

.layout-default.layout-default.layout-default {
  background-color: rgb(53, 58, 69);
}

.site-form-item-icon {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-size: 20px;
}

.modalStyle .ant-modal-header {
  border-radius: 20px 20px 0 0;
  min-height: 10vh;
  background: rgb(180, 247, 200);
  display: none;
  align-items: center;
  max-height: 0;
}
.modalStyle .ant-modal-title {
  color: rgb(53, 58, 69);
  font-size: 40px;
}
.modalStyle .ant-modal {
  min-width: 1000px;
}
.modalStyle .ant-modal-content {
  border-radius: 20px;
}

.main_colors {
  color: rgb(180, 247, 200);
  background: rgb(53, 58, 69);
}

.edit-button.edit-button.edit-button {
  &:hover {
    background-color: rgb(180, 247, 200, 0.8) !important;
    color: white;
  }
  &:disabled {
    background-color: grey !important;
  }
}

.delete-button.delete-button.delete-button {
  &:hover {
    background-color: rgb(180, 247, 200, 0.8) !important;
    color: white;
  }
}

.signout-button.signout-button.signout-button {
  background-color: white;
  &:hover {
    background-color: rgb(255, 240, 233);
  }
}

.csv-export-button {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
  -webkit-font-smoothing: antialiased;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  border-collapse: separate;
  border-spacing: 0;
  overflow-wrap: break-word;
  box-sizing: border-box;
  margin: 0;
  font-family: inherit;
  overflow: visible;
  text-transform: none;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  outline: 0;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
  -webkit-appearance: button;
  background: transparent;
  color: #1890ff;
  border-color: #1890ff;
  text-shadow: none;
  margin-left: 1rem;
  &:hover {
    background-color: #1890ff;
    color: white;
  }
  font-family: FuturaMedium !important;
  font-size: 16px !important;
  background: rgb(180, 247, 200);
  border: none !important;
  color: rgb(53, 58, 69) !important;
  /* font-weight: 500; */
  font-size: 16px !important;
  &:hover {
    border: none !important;
    background-color: rgba(180, 247, 200, 0.9) !important;
  }
}

.customer-filter-select-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 16px;

  // Desktop:
  @media only screen and (min-width: 1024px) {
    display: inline-block;
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 20px;
  }
}

.customer-filter-select-option {
  // min-width: 20rem;
}

.ant-select-selector.ant-select-selector.ant-select-selector {
  border-radius: 20px !important;
  // background: rgb(180, 247, 200) !important;
  font-family: Futura !important;
  padding: 0 !important;
  color: rgb(53, 58, 69);
  padding-left: 8px !important;
  border: 1px solid rgb(53, 58, 69) !important;
  &:hover {
    cursor: pointer;
  }
}

.ant-select-item.ant-select-item.ant-select-item {
  // background: rgb(180, 247, 200) !important;
  padding: 0 !important;
}

.test {
  color: rgb(180, 247, 200) !important; // MASTER
  color: rgb(180, 247, 202) !important;
  color: rgb(180, 247, 200) !important;
  padding: 0 !important;
}
.ant-select-selection-placeholder {
  color: rgb(53, 58, 69) !important;
  margin: 0 auto !important;
}

.customer-filter-container .ant-select .ant-select-selector {
  color: red !important;
}
.customer-filter-container {
  margin-bottom: 10rem;
}

.ant-table-thead > tr > th {
  font-family: 'Futura';
  color: rgb(180, 247, 200) !important;
  background: rgb(53, 58, 69) !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.ant-table-cell {
  font-family: 'Futura';
  color: white !important;
  background: rgb(53, 58, 69) !important;
  font-weight: bolder !important;
  font-size: 16px !important;
}
.ant-pagination-item-link {
  font-family: 'Futura';
  color: rgb(53, 58, 69) !important;
  background: rgb(180, 247, 200) !important;
  font-weight: lighter !important;
  font-size: 16px !important;
  border: none !important;
  border-radius: 7px !important;
}
.ant-pagination-item {
  font-family: 'Futura';
  color: rgb(53, 58, 69) !important;
  background: rgb(180, 247, 200) !important;
  // font-weight: lighter !important;
  font-size: 16px !important;
  border-radius: 7px !important;
  border: none !important;
  a {
    border: none !important;
    color: rgb(53, 58, 69) !important;
  }
}

//Modify list text and background color
.ant-table-tbody > tr > th {
  font-family: 'Futura';
  color: rgb(180, 247, 200) !important;
  background: rgb(53, 58, 69) !important;
  font-weight: lighter !important;
  font-size: 16px !important;
  border: none !important;
}
// .main_colors {
//   color: rgb(180, 247, 200);
//   background: rgb(53, 58, 69);
// }

.csv-export-button.csv-export-button.csv-export-button {
  font-family: 'Futura';
  background: rgb(180, 247, 200);
  color: rgb(53, 58, 69);
  /* font-weight: 500; */
  font-size: 16px;
  border: none;
  &:hover {
    border: none;
    background-color: rgba(180, 247, 200, 0.9);
  }
}

.create-lead-container {
  display: grid;
  grid-template-areas:
    'header header header header '
    'org org org orgbtn'
    'name name name name'
    'adr adr adr adr '
    'contactperson contactperson contactperson contactperson '
    'contactposition contactposition contactposition contactposition '
    'phone phone phone phone '
    'email email email email '
    '. . canceladd canceladd';

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 16px;

  .ant-form-item-label {
    padding-bottom: 0 !important;
  }

  h2 {
    font-size: 36px;
    color: rgb(53, 58, 69) !important;
  }
  p {
    font-size: 16px;
    color: rgb(53, 58, 69) !important;
    margin-bottom: 4px;
    margin-left: 8px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .create-lead-header {
    background-color: rgb(180, 247, 200);
    grid-area: header;
    min-height: 100px;
    min-width: 100%;
    border-radius: 20px 20px 0 0;
    // display: flex;
    // align-items: center;
    padding: 2rem 0;

    h2 {
      font-family: FuturaMedium;
      font-size: 42px;
      margin: 0;
      margin-left: 2rem;
      width: 100%;
    }

    p {
      font-family: FuturaMedium;
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }

  .create-lead-org {
    grid-area: org;
    margin-left: 2rem;
  }
  .create-lead-orgbtn {
    grid-area: orgbtn;
    margin-left: 0;
    margin-right: 2rem;
    margin-top: 29px;
  }
  .create-lead-name {
    grid-area: name;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-lead-adr {
    grid-area: adr;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-lead-contactperson {
    grid-area: contactperson;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-lead-contactposition {
    grid-area: contactposition;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-lead-phone {
    grid-area: phone;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-lead-email {
    grid-area: email;
    margin-right: 2rem;
    margin-left: 2rem;
  }
}

.create-customer-container {
  display: grid;
  grid-template-areas:
    'header header header header '
    'vendor vendor vendor vendor'
    'org org org orgbtn'
    'name name name name'
    'adr adr adr adr '
    'contactperson contactperson contactperson contactperson '
    'contactposition contactposition contactposition contactposition '
    'phone phone phone phone '
    'email email email email '
    '. . canceladd canceladd';

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 16px;

  .ant-form-item-label {
    padding-bottom: 0 !important;
  }

  h2 {
    font-size: 36px;
    color: rgb(53, 58, 69) !important;
  }
  p {
    font-size: 16px;
    color: rgb(53, 58, 69) !important;
    margin-bottom: 4px;
    margin-left: 8px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .create-customer-header {
    background-color: rgb(180, 247, 200);
    grid-area: header;
    min-height: 100px;
    min-width: 100%;
    border-radius: 20px 20px 0 0;
    // display: flex;
    // align-items: center;
    padding: 2rem 0;

    h2 {
      font-family: FuturaMedium;
      font-size: 42px;
      margin: 0;
      margin-left: 2rem;
      width: 100%;
    }

    p {
      font-family: FuturaMedium;
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }

  .create-customer-org {
    grid-area: org;
    margin-left: 2rem;
  }
  .create-customer-orgbtn {
    grid-area: orgbtn;
    margin-left: 0;
    margin-right: 2rem;
    margin-top: 29px;
  }
  .create-customer-name {
    grid-area: name;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-customer-vendor {
    grid-area: vendor;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-customer-adr {
    grid-area: adr;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-customer-contactperson {
    grid-area: contactperson;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-customer-contactposition {
    grid-area: contactposition;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-customer-phone {
    grid-area: phone;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-customer-email {
    grid-area: email;
    margin-right: 2rem;
    margin-left: 2rem;
  }
}

.create-feedback-container {
  display: grid;
  grid-template-areas:
    'header header header header '
    'status status status status'
    'note note note note'
    'rating rating rating rating '
    'canceladd canceladd canceladd canceladd';

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 16px;

  .ant-form-item-label {
    padding-bottom: 0 !important;
  }

  h2 {
    font-size: 36px;
    color: rgb(53, 58, 69) !important;
  }
  p {
    font-size: 16px;
    color: rgb(53, 58, 69) !important;
    margin-bottom: 4px;
    margin-left: 8px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .create-feedback-header {
    background-color: rgb(180, 247, 200);
    grid-area: header;
    min-height: 100px;
    min-width: 100%;
    border-radius: 20px 20px 0 0;
    // display: flex;
    // align-items: center;
    padding: 2rem 0;

    h2 {
      font-family: FuturaMedium;
      font-size: 42px;
      margin: 0;
      margin-left: 2rem;
      width: 100%;
    }

    p {
      font-family: FuturaMedium;
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }

  .create-feedback-status {
    grid-area: status;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-feedback-note {
    grid-area: note;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-feedback-rating {
    grid-area: rating;
    margin-right: 2rem;
    margin-left: 2rem;
  }
}

.create-user-container {
  display: grid;
  grid-template-areas:
    'header header header header '
    'firstname firstname firstname firstname'
    'lastname lastname lastname lastname'
    'email email email email '
    'isadmin isadmin isadmin isadmin '
    '. . canceladd canceladd';

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 16px;

  .ant-form-item-label {
    padding-bottom: 0 !important;
  }

  h2 {
    font-size: 36px;
    color: rgb(53, 58, 69) !important;
  }
  p {
    font-size: 16px;
    color: rgb(53, 58, 69) !important;
    margin-bottom: 4px;
    margin-left: 8px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .create-user-header {
    background-color: rgb(180, 247, 200);
    grid-area: header;
    min-height: 100px;
    min-width: 100%;
    border-radius: 20px 20px 0 0;
    // display: flex;
    // align-items: center;
    padding: 2rem 0;

    h2 {
      font-family: FuturaMedium;
      font-size: 42px;
      margin: 0;
      margin-left: 2rem;
      width: 100%;
    }

    p {
      font-family: FuturaMedium;
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }

  .create-user-firstname {
    grid-area: firstname;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .create-user-lastname {
    grid-area: lastname;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-user-isadmin {
    grid-area: isadmin;
    margin-right: 2rem;
    margin-left: 2rem;
    display: flex;
    flex-direction: row;
  }

  .create-user-email {
    grid-area: email;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
  }
}

.nav-container {
  display: grid;
  grid-template-areas: 'logo logo logo logo link1 link2 link3 link4 . name name avatar ';

  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 16px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 2rem;

  .ant-form-item-label {
    padding-bottom: 0 !important;
  }

  h2 {
    font-size: 36px;
    color: rgb(53, 58, 69) !important;
  }
  p {
    font-size: 16px;
    color: rgb(53, 58, 69) !important;
    margin-bottom: 4px;
    margin-left: 8px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .nav-logo {
    grid-area: logo;
    @media only screen and (min-width: 1024px) {
      margin-right: 2rem;
      margin-left: 2rem;
    }
  }
  .nav-link1 {
    grid-area: link1;
    display: none;
    @media only screen and (min-width: 1024px) {
      display: block;
    }
  }
  .nav-link2 {
    grid-area: link2;
    display: none;
    @media only screen and (min-width: 1024px) {
      display: block;
    }
  }
  .nav-link3 {
    grid-area: link3;
    display: none;
    @media only screen and (min-width: 1024px) {
      display: block;
    }
  }
  .nav-signout {
    grid-area: link4;
  }
  .nav-name {
    grid-area: name;
    text-align: right;
    margin-right: 1rem;
    display: none;
    @media only screen and (min-width: 1024px) {
      display: block;
    }
  }
  .nav-avatar {
    grid-area: avatar;
  }
}

.modal-button-canceladd {
  margin-bottom: 2rem;
  margin-right: 2rem;
  grid-area: canceladd;
  text-align: right;
}
.modal-button-add {
  margin-bottom: 2rem;
  grid-area: add;
}
.modal-button-cancel {
  grid-area: cancel;
  text-align: center;
  margin-bottom: 2rem;
}

.disabled {
  background-color: grey !important;
}
